/*
 * @Descripttion:
 * @version:
 * @Author: sueRimn
 * @Date: 2021-11-04 22:16:01
 * @LastEditors: sueRimn
 * @LastEditTime: 2021-11-14 15:55:26
 */
import "@babel/polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Qs from "qs";
import Vant from "vant";
import store from "./store";
import axios from "./http/http.js";
import "./utils/pxtorem.js";
import "vant/lib/index.css";
import "./assets/icons/iconfont.css";
import GSignInButton from "vue-google-signin-button";
import FBSignInButton from "vue-facebook-signin-button";

// /
var VueCookie = require("vue-cookie");
// Tell Vue to use the plugin
Vue.use(VueCookie);
Vue.use(Vant);
Vue.use(GSignInButton);
Vue.use(FBSignInButton);

// 国际化
import VueI18n from "vue-i18n";
Vue.use(VueI18n); // 通过插件的形式挂载

const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "en", // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    "zh-cn": require("./lang/zh-cn"), // 简体
    en: require("./lang/en"), // 英文
    "zh-hk": require("./lang/zh-hk"), // 繁体
    th: require("./lang/th"), // 泰语
    es: require("./lang/es"), // 西班牙语
  },
});
Vue.config.productionTip = false;
window.Qs = Qs;
Vue.prototype.$http = axios;
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
// 每次路由跳转的时候 都滚动到页面的顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
