export const lang = {
  LanguageChoice: "Selección de idiomas",
  FamiliarStyle: "中文简体",
  ComplexFont: "中文繁体",
  English: "English",
  ThaiLanguage: "ไทย", // 泰语
  SpanishLanguage: "Español", // 西班牙语
};

export const word = {
  Nomoredata: "No más datos",
  Loading: "Carga",
  Requestfailedclickreload: "La solicitud falló, haga clic en recargar",
  Writeacomment: "Escribir un comentario",
  Pleasewritesomething: "Por favor, escribe algo.",
  Reads: "Leer",
  Likes: "Como",
  Comments: "Comentario",
  Viewall: "Ver todo",
  Writecomment: "Escribir un comentario",
  StartReading: "Empezar a leer",
  ContinueReading: "Seguir leyendo",
  Buycoinstocontinuereading: "Comprar monedas para leer",
  Buychapter: "Capítulo de compra",
  Balance: "Equilibrio",
  AddtoLibrary: "Añadir a la biblioteca",
  Tap: "TAP",
  andthen: "Y luego",
  AddToHomeScreen: "Añadir a la pantalla principal",
  Autounlocknextchapter: "Desbloquear automáticamente el siguiente capítulo",
  Aa: "Aa",
  Thisisthefirstchapter: "Este es el primer capítulo.",
  Thisisthelastchapter: "Este es el último capítulo.",
  AllGenres: "Todos los tipos",
  More: "Más",
  Findmorestories: "Encontrar más historias",
  Title: "Título",
};

export const en = {
  NovelFun: "NovelFun",
  ADDTOHOMESCREEN: "Añadir a la pantalla principal",
  Tap: "TAP",
  andthen: "Y luego",
  AddToHomeScreen: "Añadir a la pantalla principal",
  Contents: "Contenido",
  Tryothers: "Prueba con los demás.",
  SeeAll: "¿Ves?",
  DownloadAPP: "Descargar aplicación",
  OpenAPP: "Aplicaciones abiertas",
  HotRecs: "Hot Records",
  SigninwithFacebook: "Iniciar sesión en facebook",
  SigninwithGoogle: "Iniciar sesión en Google",
  BycreatinganaccountIagreetothe: "Al crear una cuenta, estoy de acuerdo",
  UserAgreement: "Protocolo de usuario",
  And: "Y",
  PrivacyPolicy: "Política de privacidad",
  discover: "Descubrimiento",
  library: "Biblioteca",
  profile: "Contorno",
  BacktoHomepage: "Volver a la página principal",
  OopsnoresultsfoundWhynottryothers:
    "No hay resultados. ¿Por qué no probar el otro?",
  ChangeLanguage: "Cambiar el idioma",
  SwitchAccount: "Convertir cuenta",
  Login: "Iniciar sesión",
  YourQuestion: "Su pregunta",
  Submit: "Obediencia",
  PleaseuploadpicturesinJPGformat: "Por favor, suba una imagen en formato jpg",
  Feedbackcontentorcontactinformationcannotbeempty:
    "El contenido de retroalimentación o la información de contacto no pueden estar vacíos",
  Feedbacksubmittedsuccessfully: "¡Retroalimentación enviada con éxito!",
  Library: "Biblioteca",
  ReadTo: "Leer a",
  Findmorestories: "Encontrar más historias",
  Cancel: "Cancelar",
  Remove: "Eliminar",
  Reads: "Leer",
  Likes: "Como",
  ContinueRead: "Seguir leyendo",
  AllTags: "Todas las etiquetas",
  RemovedfromLibrary: "Eliminar de la biblioteca",
  AddedtoLibrary: "Añadir a la biblioteca",
  ThirdPartyDownloadApp: "Descarga de terceros APP",
};
